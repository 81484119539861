<template>
    <div class="photos">
        <div class="container">
            <h2 class="photos__title">
                <span v-if="language === 'ru'">Фотогалерея</span>
                <span v-else>Photo Gallery</span>
            </h2>

            <div class="photos__desktop" >
                <div class="photos__row _top">
                    <img class="photos__img _photo1_1" :src="require(`@/assets/images/photos_mobile/1_line/photo1_1.jpg`)"
                        @click="openPopup(require(`@/assets/images/photos_mobile/1_line/photo1_1.jpg`))" />
                    <img class="photos__img _photo1_2" :src="require(`@/assets/images/photos_mobile/1_line/photo1_2.jpg`)"
                        @click="openPopup(require(`@/assets/images/photos_mobile/1_line/photo1_2.jpg`))" />
                    <img class="photos__img _photo1_3" :src="require(`@/assets/images/photos_mobile/1_line/photo1_3.jpg`)"
                        @click="openPopup(require(`@/assets/images/photos_mobile/1_line/photo1_3.jpg`))" />
                </div>
                <div class="photos__row _middle">
                    <div class="photos__row-wrap _column">
                        <img class="photos__img _photo2_1" :src="require(`@/assets/images/photos_mobile/2_line/photo2_1.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_1.jpg`))" />
                        <img class="photos__img _photo2_2" :src="require(`@/assets/images/photos_mobile/2_line/photo2_2.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_2.jpg`))" />
                    </div>
                    <div class="photos__row-wrap _column">
                        <img class="photos__img _photo2_3" :src="require(`@/assets/images/photos_mobile/2_line/photo2_3.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_3.jpg`))" />
                        <img class="photos__img _photo2_4" :src="require(`@/assets/images/photos_mobile/2_line/photo2_4.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_4.jpg`))" />
                    </div>
                    <div class="photos__row-wrap _column">
                        <div class="photos__row-wrap _row">
                            <div class="photos__row-wrap _column">
                                <img class="photos__img _photo2_5"
                                    :src="require(`@/assets/images/photos_mobile/2_line/photo2_5.jpg`)"
                                    @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_5.jpg`))" />
                                <img class="photos__img _photo2_6"
                                    :src="require(`@/assets/images/photos_mobile/2_line/photo2_6.jpg`)"
                                    @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_6.jpg`))" />
                            </div>
                            <img class="photos__img _photo2_8"
                                :src="require(`@/assets/images/photos_mobile/2_line/photo2_8.jpg`)"
                                @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_8.jpg`))" />
                        </div>
                        <div class="photos__row-wrap _row">
                            <img class="photos__img _photo2_7"
                                :src="require(`@/assets/images/photos_mobile/2_line/photo2_7.jpg`)"
                                @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_7.jpg`))" />
                            <img class="photos__img _photo2_9"
                                :src="require(`@/assets/images/photos_mobile/2_line/photo2_9.jpg`)"
                                @click="openPopup(require(`@/assets/images/photos_mobile/2_line/photo2_9.jpg`))" />
                        </div>
                    </div>
                </div>
                <div class="photos__row _bottom">
                    <div class="photos__row-wrap _column">
                        <img class="photos__img _photo3_1" :src="require(`@/assets/images/photos_mobile/3_line/photo3_1.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_1.jpg`))" />
                        <img class="photos__img _photo3_2" :src="require(`@/assets/images/photos_mobile/3_line/photo3_2.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_2.jpg`))" />
                    </div>
                    <div class="photos__row-wrap _column">
                        <img class="photos__img _photo3_3" :src="require(`@/assets/images/photos_mobile/3_line/photo3_3.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_3.jpg`))" />
                        <div class="photos__row-wrap _row">
                            <img class="photos__img _photo3_4"
                                :src="require(`@/assets/images/photos_mobile/3_line/photo3_4.jpg`)"
                                @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_4.jpg`))" />
                            <img class="photos__img _photo3_5"
                                :src="require(`@/assets/images/photos_mobile/3_line/photo3_5.jpg`)"
                                @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_5.jpg`))" />
                        </div>
                    </div>
                    <div class="photos__row-wrap _column">
                        <img class="photos__img _photo3_6" :src="require(`@/assets/images/photos_mobile/3_line/photo3_6.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_6.jpg`))" />
                        <img class="photos__img _photo3_7" :src="require(`@/assets/images/photos_mobile/3_line/photo3_7.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_7.jpg`))" />
                    </div>
                    <div class="photos__row-wrap _column">
                        <img class="photos__img _photo3_8" :src="require(`@/assets/images/photos_mobile/3_line/photo3_8.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_8.jpg`))" />
                        <img class="photos__img _photo3_9" :src="require(`@/assets/images/photos_mobile/3_line/photo3_9.jpg`)"
                            @click="openPopup(require(`@/assets/images/photos_mobile/3_line/photo3_9.jpg`))" />
                    </div>
                </div>
            </div>

            <div class="photos__mobile">
                <Carousel :slides-count="photos.length">
                    <div v-for="photo in photos" :key="photo.id" class="photos__slide">
                        <img :src="photo.src" :alt="photo.alt" class="photos__slide-img">
                    </div>
                </Carousel>
            </div>
        </div>
    </div>

    <div v-if="showPopup" class="overlay" @click="closePopup">
        <div class="popup">
            <img :src="currentImage" />
        </div>

    </div>
</template>

<script setup>
import { reactive, computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex"
import Carousel from './Carousel.vue'

const name = "Photos";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const photos = ref([
    {
        id: 1,
        src: require('@/assets/images/photos_mobile/1_line/photo1_1.jpg'),
        alt: 'Photo 1'
    },
    {
        id: 2,
        src: require('@/assets/images/photos_mobile/1_line/photo1_2.jpg'),
        alt: 'Photo 2'
    },
    {
        id: 3,
        src: require('@/assets/images/photos_mobile/1_line/photo1_3.jpg'),
        alt: 'Photo 3'
    },

    {
        id: 4,
        src: require('@/assets/images/photos_mobile/2_line/photo2_1.jpg'),
        alt: 'Photo 4'
    },
    {
        id: 5,
        src: require('@/assets/images/photos_mobile/2_line/photo2_2.jpg'),
        alt: 'Photo 5'
    },
    {
        id: 6,
        src: require('@/assets/images/photos_mobile/2_line/photo2_3.jpg'),
        alt: 'Photo 6'
    },

    {
        id: 7,
        src: require('@/assets/images/photos_mobile/2_line/photo2_4.jpg'),
        alt: 'Photo 7'
    },
    {
        id: 8,
        src: require('@/assets/images/photos_mobile/2_line/photo2_5.jpg'),
        alt: 'Photo 8'
    },
    {
        id: 9,
        src: require('@/assets/images/photos_mobile/2_line/photo2_6.jpg'),
        alt: 'Photo 9'
    },

    {
        id: 10,
        src: require('@/assets/images/photos_mobile/2_line/photo2_7.jpg'),
        alt: 'Photo 10'
    },

    {
        id: 11,
        src: require('@/assets/images/photos_mobile/2_line/photo2_8.jpg'),
        alt: 'Photo 11'
    },

    {
        id: 12,
        src: require('@/assets/images/photos_mobile/2_line/photo2_9.jpg'),
        alt: 'Photo 12'
    },

    {
        id: 13,
        src: require('@/assets/images/photos_mobile/3_line/photo3_1.jpg'),
        alt: 'Photo 13'
    },

    {
        id: 14,
        src: require('@/assets/images/photos_mobile/3_line/photo3_2.jpg'),
        alt: 'Photo 14'
    },

    {
        id: 15,
        src: require('@/assets/images/photos_mobile/3_line/photo3_3.jpg'),
        alt: 'Photo 15'
    },

    {
        id: 16,
        src: require('@/assets/images/photos_mobile/3_line/photo3_4.jpg'),
        alt: 'Photo 16'
    },

    {
        id: 17,
        src: require('@/assets/images/photos_mobile/3_line/photo3_5.jpg'),
        alt: 'Photo 17'
    },

    {
        id: 18,
        src: require('@/assets/images/photos_mobile/3_line/photo3_6.jpg'),
        alt: 'Photo 18'
    },

    {
        id: 19,
        src: require('@/assets/images/photos_mobile/3_line/photo3_7.jpg'),
        alt: 'Photo 19'
    },

    {
        id: 20,
        src: require('@/assets/images/photos_mobile/3_line/photo3_8.jpg'),
        alt: 'Photo 20'
    },

    {
        id: 21,
        src: require('@/assets/images/photos_mobile/3_line/photo3_9.jpg'),
        alt: 'Photo 21'
    },
])


const showPopup = ref(false);
const currentImage = ref('');

function openPopup(imageSrc) {
    currentImage.value = imageSrc;
    showPopup.value = true;
    document.body.style.overflow = 'hidden';
}

function closePopup() {
    showPopup.value = false;
    document.body.style.overflow = '';
}
</script>

<style lang="scss">
@import '../../styles/global.scss';

.photos {
    padding-top: 50px;

    @media screen and (max-width: ($mobile + 1)) {
        padding-top: 30px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding-top: 60px;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 60px;
        color: #2B3D8D;
        text-transform: uppercase;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }

    &__desktop {
        @media screen and (max-width: $tablet-middle) {
            display: none;
        }
    }

    &__mobile {
        display: none;

        @media screen and (max-width: $tablet-middle) {
            display: block;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        &._top {
            margin-bottom: 2px;
        }

        &._middle {
            margin-bottom: 2px;
        }
    }

    &__row-wrap {
        display: flex;
        justify-content: space-between;

        &._column {
            flex-direction: column;
            gap: 2px;
        }

        &._row {
            flex-direction: row;
            gap: 2px;
        }
    }

    &__img {
        display: block;
        cursor: pointer;

        &._photo1_1 {
            width: 352px;
            height: 264px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.332);
                height: calc((100vw - 50px)*0.249);
            }
        }

        &._photo1_2 {
            width: 352px;
            height: 264px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.332);
                height: calc((100vw - 50px)*0.249);
            }
        }

        &._photo1_3 {
            width: 352px;
            height: 264px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.332);
                height: calc((100vw - 50px)*0.249);
            }
        }

        &._photo2_1 {
            width: 263px;
            height: 331px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.248);
                height: calc((100vw - 50px)*0.312);
            }
        }

        &._photo2_2 {
            width: 263px;
            height: 197px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.248);
                height: calc((100vw - 50px)*0.186);
            }
        }

        &._photo2_3 {
            width: 263px;
            height: 197px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.248);
                height: calc((100vw - 50px)*0.186);
            }
        }

        &._photo2_4 {
            width: 263px;
            height: 331px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.248);
                height: calc((100vw - 50px)*0.312);
            }
        }

        &._photo2_5 {
            width: 176px;
            height: 131px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.166);
                height: calc((100vw - 50px)*0.123);
            }
        }

        &._photo2_6 {
            width: 176px;
            height: 131px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.166);
                height: calc((100vw - 50px)*0.123);
            }
        }

        &._photo2_7 {
            width: 352px;
            height: 264px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.332);
                height: calc((100vw - 50px)*0.249);
            }
        }

        &._photo2_8 {
            width: 352px;
            height: 264px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.332);
                height: calc((100vw - 50px)*0.249);
            }
        }

        &._photo2_9 {
            width: 176px;
            height: 264px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.166);
                height: calc((100vw - 50px)*0.249);
            }
        }

        &._photo3_1 {
            width: 263px;
            height: 197px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.248);
                height: calc((100vw - 50px)*0.186);
            }
        }

        &._photo3_2 {
            width: 263px;
            height: 197px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.248);
                height: calc((100vw - 50px)*0.186);
            }
        }

        &._photo3_3 {
            width: 352px;
            height: 264px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.332);
                height: calc((100vw - 50px)*0.249);
            }
        }

        &._photo3_4 {
            width: 176px;
            height: 131px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.166);
                height: calc((100vw - 50px)*0.123);
            }
        }

        &._photo3_5 {
            width: 174px;
            height: 131px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.164);
                height: calc((100vw - 50px)*0.123);
            }
        }

        &._photo3_6 {
            width: 263px;
            height: 197px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.248);
                height: calc((100vw - 50px)*0.186);
            }
        }

        &._photo3_7 {
            width: 263px;
            height: 197px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.248);
                height: calc((100vw - 50px)*0.186);
            }
        }

        &._photo3_8 {
            width: 176px;
            height: 130px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.166);
                height: calc((100vw - 50px)*0.122);
            }
        }

        &._photo3_9 {
            width: 176px;
            height: 264px;

            @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
                width: calc((100vw - 50px)*0.166);
                height: calc((100vw - 50px)*0.249);
            }
        }
    }

    &__slide {
        flex: 0 0 100%;
        height: 100%;
    }

    &__slide-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    /* Затемнение фона */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
}

.popup {
    width: 50vw;
    cursor: pointer;

    @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
        width: 85vw;
    }
    /* Ширина окна - 50% от ширины экрана */
}

.popup img {
    width: 100%;
    /* Картинка занимает всю ширину окна */
}
</style>