<template>
    <div class="release" id="post-release">
        <div class="container">
            <h2 class="release__title">{{ title }}</h2>
            <p v-for="item in text" class="release__text">{{ item }}</p>
        </div>
    </div>

</template>

<script setup>
import { reactive, computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex"

const name = "PostRelease";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const title = computed(() => data.main.release.title);
const text = computed(() => data.main.release.text);

</script>

<style lang="scss">
@import '../../styles/global.scss';

.release {
    padding-top: 100px;

    @media screen and (max-width: ($mobile + 1)) {
        padding-top: 30px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding-top:60px;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 40px;
        color: #2B3D8D;
        text-transform: uppercase;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }

    &__text {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: justify;
        color: #333333;
        margin-bottom: 10px;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
        }

        & ._bottom {

            &:last-child {
                text-align: right;
            }
        }
    }
}
</style>