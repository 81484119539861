<template>
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9736 8.49999L0.763106 16.7044L0.763106 0.295538L14.9736 8.49999Z" fill="white" />
    </svg>
</template>

<script>
export default {
    name: "IconPlay"
}
</script>

<style scoped></style>