<template>
    <div class="videos">
        <div class="container">
            <h2 class="videos__title">{{ title }}</h2>
            <div class="videos__wrapper">
                <a v-for="item in list" :href="item.link" class="videos__link" target="_blank">
                    <p class="videos__icon">
                        <IconPlay />
                    </p>
                    <p class="videos__wrap">
                        <span class="videos__date">{{ item.date }}</span>
                        <span v-if="item.add" class="videos__add">{{ item.add }}</span>
                    </p>
                </a>
            </div>

        </div>
    </div>

</template>

<script setup>
import IconPlay from "@/assets/images/icons/IconPlay.vue";
import { reactive, computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex"

const name = "Videos";

const store = useStore();
const data = store.state.currentData;

const language = computed(() => store.state.currentLanguage);

const title = computed(() => data.main.videos.title);
const list = computed(() => data.main.videos.list);
</script>

<style lang="scss">
@import '../../styles/global.scss';

.videos {
    padding-top: 100px;

    @media screen and (max-width: ($mobile + 1)) {
        padding-top: 30px;
    }

    @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
        padding-top: 60px;
    }

    &__title {
        font-size: 38px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        margin-top: 0;
        margin-bottom: 60px;
        color: #2B3D8D;
        text-transform: uppercase;

        @media screen and (max-width: ($mobile + 1)) {
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 20px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-max) {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__link {
        display: flex;
        justify-content: center;
        width: 340px;
        height: 108px;
        padding-top: 32px;
        box-sizing: border-box;
        border-radius: 10px;
        background: linear-gradient(273.87deg, #3872B2 21.85%, #2B3D8D 77.8%);
        color: #fff;
        text-decoration: none;

        @media screen and (max-width: ($mobile + 1)) {
            width: 100%;
            height: 54px;
            padding-top: 16px;
            border-radius: 8px;
        }

        @media screen and (min-width: $tablet-min) and (max-width: $tablet-middle) {
            width: calc(50% - 10px);
            height: 54px;
            padding-top: 16px;
            border-radius: 8px;
        }

        @media screen and (min-width: ($tablet-middle + 1)) and (max-width: $tablet-max) {
            width: calc(50% - 10px);
        }

    }

    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        margin-right: 19px;
        background: #3773B3;

        @media screen and (max-width: ($tablet-middle)) {
            width: 22px;
            height: 22px;
            margin-right: 11px;

            & svg {
                width: 9.26px;
                height: 9.26px;
            }
        }
    }

    &__wrap {
        margin-top: 8px;

        @media screen and (max-width: ($tablet-middle)) {
            margin-top: 4px;
        }
    }

    &__date {
        display: block;
        font-size: 30px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 2px;

        @media screen and (max-width: ($tablet-middle)) {
            font-size: 16px;
            line-height: 16px;

        }
    }

    &__add {
        display: block;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: right;

        @media screen and (max-width: ($tablet-middle)) {
            font-size: 9px;
            line-height: 9px;
        }
    }
}
</style>