<template>
    <div ref="carouselRef" class="carousel" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">
        <button class="carousel__btn carousel__btn--prev" @click="prevSlide">
            <IconSliderArrow />
        </button>

        <div class="carousel__slides">
            <div class="carousel__slide" :style="{
                transform: `translateX(${translateX}px)`,
                transition: isDragging ? 'none' : 'transform 0.3s ease'
            }">
                <slot></slot>
            </div>
        </div>

        <button class="carousel__btn carousel__btn--next" @click="nextSlide">
            <IconSliderArrow />
        </button>

        <div class="carousel__counter">{{ currentIndex + 1 }} / {{ slidesCount }}</div>
    </div>
</template>

<script setup>
import IconSliderArrow from '@/assets/images/icons/IconSliderArrow.vue'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
    slidesCount: {
        type: Number,
        required: true
    }
})

const carouselRef = ref(null)
const currentIndex = ref(0)
const touchStartX = ref(0)
const touchEndX = ref(0)
const isDragging = ref(false)
const dragOffset = ref(0)
const slideWidth = ref(0)


const translateX = computed(() => {
    return -(currentIndex.value * slideWidth.value) + dragOffset.value
})

const touchStart = (e) => {
    isDragging.value = true
    touchStartX.value = e.touches[0].clientX
    touchEndX.value = e.touches[0].clientX
}

const touchMove = (e) => {
    if (!isDragging.value) return

    touchEndX.value = e.touches[0].clientX
    const diff = touchEndX.value - touchStartX.value

    // Ограничиваем смещение
    const maxOffset = slideWidth.value * 0.4 // максимальное смещение 40% от ширины
    dragOffset.value = Math.max(Math.min(diff, maxOffset), -maxOffset)
}

const touchEnd = () => {
    isDragging.value = false
    const diff = touchEndX.value - touchStartX.value

    // Определяем порог для смены слайда (20% от ширины слайда)
    const threshold = slideWidth.value * 0.2

    if (Math.abs(diff) > threshold) {
        if (diff > 0 && currentIndex.value > 0) {
            currentIndex.value--
        } else if (diff < 0 && currentIndex.value < props.slidesCount - 1) {
            currentIndex.value++
        }
    }

    dragOffset.value = 0
}

const nextSlide = () => {
    if (currentIndex.value < props.slidesCount - 1) {
        currentIndex.value++
    } else {
        currentIndex.value = 0
    }
}

const prevSlide = () => {
    if (currentIndex.value > 0) {
        currentIndex.value--
    } else {
        currentIndex.value = props.slidesCount - 1
    }
}



const updateSlideWidth = () => {
    if (carouselRef.value) {
        slideWidth.value = carouselRef.value.clientWidth
    }
}

onMounted(() => {
    updateSlideWidth()
    window.addEventListener('resize', updateSlideWidth)
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateSlideWidth)
})
</script>

<style lang="scss">
.carousel {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__slides {
        height: 100%;
        overflow: hidden;
    }

    &__slide {
        display: flex;
        align-items: center;
        transition: transform 0.5s ease;
        height: 100%;
    }


    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        padding: 0;
        border-radius: 50%;
        background: #00000099;
        border: none;
        cursor: pointer;
        z-index: 2;
    }

    &__btn--prev {
        left: 10px;
    }

    &__btn--next {
        right: 10px;

        & svg {
            rotate: 180deg;
        }
    }

    &__counter {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 5px 10px;
        border-radius: 15px;
    }
}
</style>